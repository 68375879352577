export const actionTypes = {
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  CREATE_CLIENT: 'CREATE_CLIENT',
  DELETE_CLIENT: 'DELETE_CLIENT',
  USERS_BY_CLIENT: 'USERS_BY_CLIENT',
  EDIT_USERS_BY_CLIENT: 'EDIT_USERS_BY_CLIENT',
  LOCATIONS_BY_CLIENT: 'LOCATIONS_BY_CLIENT',
  EDIT_LOCATIONS_BY_CLIENT: 'EDIT_LOCATIONS_BY_CLIENT',
  SURVEYS_BY_CLIENT: 'SURVEYS_BY_CLIENT',
  EDIT_SURVEYS_BY_CLIENT: 'EDIT_SURVEYS_BY_CLIENT',
  CLEAR_CLIENTS: 'CLEAR_CLIENTS',
  CURRENT_CLIENTS: 'CLEAR_CLIENTS',
};
