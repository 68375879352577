import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Index from './pages/Index/Index';
import Navigation from './components/Navigation/Navigation';
import Users from './pages/Users/Users';
import Clients from './pages/Clients/Clients';
import Locations from './pages/Locations/Locations';
import Surveys from './pages/Surveys/Surveys';
import Assignments from './pages/Assignments/Assignments';
import RequireLoggedInUser from './components/Auth/RequireLoggedInUser/RequireLoggedInUser';
import Survey from './pages/Surveys/Survey';
import Reports from './pages/Reports/Reports';
import Tags from './pages/Tags/Tags';
import ApplicationLayout from './components/Layout/ApplicationLayout';

export const App = () => (
  <div className="App">
    <Router>
      <RequireLoggedInUser>
        <ApplicationLayout>
          <Navigation />
          <Route exact path="/" component={Index} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/clients" component={Clients} />
          <Route exact path="/locations" component={Locations} />
          <Route exact path="/surveys" component={Surveys} />
          <Route exact path="/assignments" component={Assignments} />
          <Route exact path="/survey/:surveyId" component={Survey} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/tags" component={Tags} />
          <div>Ver. 0.05032024</div>
        </ApplicationLayout>
      </RequireLoggedInUser>
    </Router>
  </div>
);

export default App;
