import React, { useState } from 'react';
import {
  Button, Select, Form, List, Input, Space, Modal,
} from 'antd';
import PropTypes from 'prop-types';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import AsignmentSteps from './AsignmentSteps';

export const AssignmetForm = ({
  users = [],
  surveys = [],
  locations = [],
  handleCreateAssignment = () => {},
  handleSelectSurvey = () => {},
  disableLocations = false,
}) => {
  const [form] = Form.useForm();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [search, setSearch] = useState('');

  const [assignmentModal, setAssignmentModal] = useState(false);

  const handleSelectLocation = (id) => {
    const found = selectedLocations.find((obj) => obj.id === id);

    if (found) { return; }

    const location = locations.filter((loc) => loc.id === id);
    const sLocations = [...selectedLocations];
    sLocations.push(location[0]);
    setSelectedLocations(sLocations);
  };

  const handleRemoveSelected = (item) => {
    const index = selectedLocations.indexOf(item);
    if (index >= 0) {
      const sLocations = [...selectedLocations];
      sLocations.splice(index, 1);
      setSelectedLocations(sLocations);
    }
  };

  const toggleAssignmentModal = () => {
    setAssignmentModal(!assignmentModal);
  };

  return (
    <div>
      <h6>
        <b>Nueva asignación</b>
      </h6>
      <div className="assignment-form">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={(data) => handleCreateAssignment({ ...data, selectedLocations }, () => { form.resetFields(); setSelectedLocations([]); })}
          onFinishFailed={() => {}}
        >
          <Form.Item
            name="survey"
            label="Seleccionar encuesta"
            rules={[{ required: true, message: 'Encuesta es requerida' }]}
          >
            <Select
              onChange={handleSelectSurvey}
              showSearch
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              {surveys.map((s) => (
                <Select.Option key={s.id} value={s.id}>
                  {s.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="user"
            label="Seleccionar usuario"
            rules={[{ required: true, message: 'Usuario es requerida' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input
                  .toLowerCase()) >= 0}
            >
              {users.map((u) => (
                <Select.Option key={u.id} value={u.id}>
                  {u.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="location"
            label="Seleccionar PDV"
            rules={[{ required: true, message: 'PDV es requerido' }]}
          >
            <Select
              showSearch
              onSelect={handleSelectLocation}
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
              placeholder=""
            >
              {locations.map((l) => (
                <Select.Option
                  key={l.id}
                  value={l.id}
                  disabled={disableLocations || selectedLocations.includes(l)}
                >
                  {l.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <hr />

          <Form.Item>
            <Input placeholder="Buscar Seleccionados" value={search} onChange={(e) => setSearch(e.target.value)} prefix={<SearchOutlined />} />
          </Form.Item>

          <Form.Item
            name="selectedLocations"
          >
            <List
              bordered
              style={{ height: '300px', overflow: 'scroll' }}
              locale={{ emptyText: 'Sin elementos' }}
              dataSource={selectedLocations.filter((l) => search.length === 0 || (search.length > 0 && l.name.toLowerCase().includes(search.toLowerCase())))}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                >
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap',
                  }}
                  >
                    <div>{item.name}</div>
                    <div><CloseOutlined onClick={() => { handleRemoveSelected(item); }} /></div>
                  </div>
                </List.Item>
              )}
            />
          </Form.Item>

          <Form.Item>
            <p>
              Seleccionados:
              {selectedLocations.length}
            </p>
          </Form.Item>
          <Space>
            <div>
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </div>
            <div>
              <Button type="primary" onClick={toggleAssignmentModal}>
                Etiquetas
              </Button>
            </div>
          </Space>

        </Form>
      </div>
      <Modal
        title="Asignar por etiquetas"
        centered
        visible={assignmentModal}
        okText="Cancelar"
        onOk={toggleAssignmentModal}
        onCancel={toggleAssignmentModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1000}
      >
        <AsignmentSteps toggleModal={toggleAssignmentModal} />
      </Modal>
    </div>
  );
};

AssignmetForm.propTypes = {
  users: PropTypes.array,
  surveys: PropTypes.array,
  locations: PropTypes.array,
  handleCreateAssignment: PropTypes.func,
  handleSelectSurvey: PropTypes.func,
  disableLocations: PropTypes.bool,
};

export default AssignmetForm;
