import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input } from 'antd';

const SurveyForm = ({ handleCreateSurvey }) => {
  const tailLayout = {
    wrapperCol: {
      offset: 0,
    },
  };

  const [form] = Form.useForm();

  return (
    <div className="survey-form">
      <h6><b>Nueva encuesta</b></h6>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={(data) => handleCreateSurvey(data, form.resetFields)}
        onFinishFailed={() => {}}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa nombre de usuario',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            {
              required: true,
              message: 'Ingresa la descripción de la encuesta',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

SurveyForm.propTypes = {
  handleCreateSurvey: PropTypes.func,
  form: PropTypes.object,
};

export default SurveyForm;
