import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Table, Button, Tabs, Form, Input, Select, Transfer, Modal,
} from 'antd';
import { SearchOutlined } from '@material-ui/icons';
import TagsOutlined from '@ant-design/icons/lib/icons/TagsOutlined';
import {
  createCSVLocation,
  createLocation,
  deleteLocation,
  getLocations,
} from '../../store/locations/actions';
import PageLayout from '../../components/Layout/PageLayout';
import LocationForm from './LocationForm';
import LocationFileUpload from './LocationFileUpload';
import { selectCurrentUser } from '../../store/auth/auth.selector';
import { addTagOnItem, getTagsForItem, removeTagOnItem } from '../../store/tags/tags.actions';
import { selectItemTags, selectTags } from '../../store/tags/tags.selector';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { actionTypes } from '../../store/tags/tags.types';
import { useHasErrors } from '../../utils/app/error/error.hooks';

export const Locations = ({
  token,
  locations = [],
  onGetLocations = () => {},
  onCreateLocation = () => {},
  onDeleteLocation = () => {},
  onCreateCSVLocation = (formData, token, onFinish) => {
    console.log('uploading');
    createCSVLocation(formData, token, onFinish);
  },
}) => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [zones, setZones] = useState([]);
  const dispatch = useDispatch();
  const { error } = Modal;

  const tags = useSelector(selectTags);
  const itemTags = useSelector(selectItemTags);
  const selectorCurrentUser = useSelector(selectCurrentUser);

  const [tagModal, setTagModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [targetUsers, setTargetUsers] = useState([]);
  const [selectedKeyUsers, setSelectedKeyUsers] = useState([]);

  const [getIsLoading, getIsFinished] = useIsLoading([actionTypes.GET_TAGS_FOR_ITEM]);
  const [getError, getHasError] = useHasErrors([actionTypes.GET_TAGS_FOR_ITEM]);

  const onChangeUsers = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    if (operation === 0) {
      console.log(nextTargetKeys);// tagId, itemId, type
      console.log(selectedItem);
      console.log(0);
      dispatch(addTagOnItem({ itemId: selectedItem, items: nextTargetKeys, type: 1 }));
    } else { // will delete Items
      console.log(moveKeys);// tagId, itemId, type
      console.log(selectedItem);
      console.log(0);
      dispatch(removeTagOnItem({ itemId: selectedItem, items: moveKeys, type: 1 }));
    }
    setTargetUsers(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeyUsers([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const toggleTagModal = () => { setTagModal(!tagModal); };

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const countries = [
    { name: 'Costa Rica', value: 'CR - ' },
    { name: 'El Salvador', value: 'ES - ' },
    { name: 'Guatemala', value: 'GT - ' },
    { name: 'Honduras', value: 'HN - ' },
    { name: 'Panama', value: 'PTY - ' },
    { name: 'República Dominicana', value: 'RD - ' },
  ];

  const handleCreateLocation = (location, reset) => {
    onCreateLocation(location, token);
    reset();
  };

  const showDeleteConfirmation = (locationId) => {
    setSelectedLocationId(locationId);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    onDeleteLocation(selectedLocationId, token);
    setDeleteModalVisible(false);
    setSelectedLocationId(null);
  };

  const handleCreateCSVLocation = (file, onFinish) => {
    console.log('selecting file');
    const formData = new FormData();
    formData.append('file', file.file);
    onCreateCSVLocation(formData, token, onFinish);
  };

  useEffect(() => {
    onGetLocations(token);
  }, [onGetLocations, token]);

  useEffect(() => {
    const nZones = [...new Set(locations.map((item) => item.zone))];
    setZones(nZones);
  }, [locations]);

  useEffect(() => {
    if (getIsFinished) {
      if (getHasError) {
        error({
          title: '¡Uh-oh!',
          content: getError.message || 'Ocurrio un error inesperado',
        });
      } else {
        setTargetUsers(itemTags.map((i) => i.id));
        setTagModal(true);
      }
    }
  }, [getIsLoading, getHasError]);

  const cols = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Zona',
      dataIndex: 'zone',
      key: 'zone',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Button danger onClick={() => showDeleteConfirmation(id)}>
          Eliminar
        </Button>
      ),
    },
    {
      title: 'Etiquetas',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <>
          {selectorCurrentUser.roles.find((x) => x.id === 3)
              && (
              <Button onClick={() => { setSelectedItem(id); dispatch(getTagsForItem({ itemId: id, type: 1 })); }} icon={<TagsOutlined style={{ verticalAlign: 'middle' }} />} color="#55acee">
                Agregar
              </Button>
              )}
        </>
      ),
    },
  ];
  const locationsTable = locations
    .filter((l) => !l.deleted)
    .map((l) => ({ ...l, key: l.id }));

  const handleSearchChange = (changedValues, allValues) => {
    let query = '';

    if (allValues.locationName && allValues.locationName.length > 2) {
      query += `&name=${encodeURIComponent(allValues.locationName)}`;
    }

    if (allValues.country && allValues.country.length > 0) {
      query += `&country=${encodeURIComponent(allValues.country)}`;
    }

    if (allValues.zone && allValues.zone.length > 0) {
      query += `&zone=${encodeURIComponent(allValues.zone)}`;
    }
    onGetLocations(token, query);
  };

  const search = () => (
    <div>
      <Form form={form} layout="inline" onValuesChange={handleSearchChange}>
        <Form.Item
          name="locationName"
          style={{ minWidth: '250px' }}
        >
          <Input prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="Nombre del PDV" allowClear />
        </Form.Item>

        <Form.Item
          name="country"
          style={{ minWidth: '250px' }}
        >
          <Select
            showSearch
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            placeholder="Pais"
            allowClear
          >
            {countries.map((l, i) => (
              <Select.Option
                key={i}
                value={l.value}
              >
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="zone"
          style={{ minWidth: '250px' }}
        >
          <Select
            showSearch
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            placeholder="Zona"
            allowClear
          >
            {zones.map((l, i) => (
              <Select.Option
                key={i}
                value={l}
              >
                {l}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>
      <div style={{ height: '10px' }} />
    </div>
  );

  return (
    <PageLayout
      sider={(
        <Tabs defaultActiveKey="1">
          <TabPane tab="Nuevo PDV" key="1">
            <LocationForm handleCreateLocation={handleCreateLocation} />
          </TabPane>
          <TabPane tab="Carga Masiva" key="2">
            <LocationFileUpload handleCreateCSVLocation={handleCreateCSVLocation} />
          </TabPane>
        </Tabs>
      )}
      title="Puntos de venta"
      topBar={search()}
    >
      <div className="Locations">
        <Table columns={cols} scroll={{ y: '50vh' }} dataSource={locationsTable} />
      </div>
      <Modal
        title="Editar Etiquetas"
        centered
        visible={tagModal}
        okText="Editar"
        onOk={toggleTagModal}
        onCancel={toggleTagModal}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            showSearch
            operations={['Agregar', 'Eliminar']}
            filterOption={filterOption}
            dataSource={tags ? tags.filter((t) => t.type === 'Puntos de venta').map((u) => ({ id: u.id, title: `${u.tagText}`, key: u.id })).sort((a, b) => (a.title < b.title ? -1 : 1)) : []}
            titles={['Disponibles', 'Actuales']}
            targetKeys={targetUsers}
            selectedKeys={selectedKeyUsers}
            onChange={onChangeUsers}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
          />
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirmar"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={() => {
          setDeleteModalVisible(false);
          setSelectedLocationId(null);
        }}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <p>¿Está seguro que desea eliminar este punto de venta?</p>
      </Modal>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  locations: state.locations.data,
  token: state.auth.currentUser.token,
});

const mapDispatchToProps = {
  onGetLocations: getLocations,
  onCreateLocation: createLocation,
  onDeleteLocation: deleteLocation,
  onCreateCSVLocation: createCSVLocation,
};

Locations.propTypes = {
  locations: PropTypes.array,
  onGetLocations: PropTypes.func,
  onCreateLocation: PropTypes.func,
  onDeleteLocation: PropTypes.func,
  onCreateCSVLocation: PropTypes.func,
  token: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
