import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../../store/auth/actions';
import './style.css';

export const Login = ({ handleLogin = () => {} }) => {
  const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 5,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 0,
    },
  };

  const onLogIn = (loginData) => {
    handleLogin({ email: loginData.email, password: loginData.password });
  };

  return (
    <div className="Login">
      <h6><b>Ingresar</b></h6>
      <Form
        {...layout}
        layout="vertical"
        name="Ingresar"
        onFinish={(values) => onLogIn(values)}
        onFinishFailed={() => {}}
      >
        <Form.Item
          label="Usuario"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa tu usuario',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

Login.propTypes = {
  handleLogin: PropTypes.func,
  handleRegister: PropTypes.func,
};

const mapDispatToProps = {
  handleLogin: login,
};

export default withRouter(connect(null, mapDispatToProps)(Login));
