import { executeAction } from '../../utils/app/app.actions';
import ClientsService from './clients.service';
import { actionTypes } from './clients.types';

export const getClients = () => (dispatch) => {
  const process = () => ClientsService.getClients();
  dispatch(executeAction(actionTypes.GET_ALL_CLIENTS, process));
};

export const createClient = ({ name }) => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.createClient({ name });
    await ClientsService.getClients();
    return result;
  };
  dispatch(executeAction(actionTypes.CREATE_CLIENT, process));
};

export const deleteClients = ({ id }) => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.deleteClient(id);
    await ClientsService.getClients();
    return result;
  };
  dispatch(executeAction(actionTypes.DELETE_CLIENT, process));
};

export const getUsersOnClient = ({ id }) => (dispatch) => {
  const process = () => ClientsService.getUsersOnClient(id);
  dispatch(executeAction(actionTypes.USERS_BY_CLIENT, process));
};

export const editUsersOnClient = ({ clientId, userList, operation }) => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.editUsersOnClient({ clientId, userList, operation });
    await ClientsService.getUsersOnClient(clientId);
    return result;
  };
  dispatch(executeAction(actionTypes.EDIT_USERS_BY_CLIENT, process));
};

export const getLocationsOnClient = ({ id }) => (dispatch) => {
  const process = () => ClientsService.getLocationsOnClient(id);
  dispatch(executeAction(actionTypes.LOCATIONS_BY_CLIENT, process));
};

export const editLocationsOnClient = ({ clientId, locationsList, operation }) => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.editLocationsOnClient({ clientId, locationsList, operation });
    await ClientsService.getLocationsOnClient(clientId);
    return result;
  };
  dispatch(executeAction(actionTypes.EDIT_LOCATIONS_BY_CLIENT, process));
};

export const getSurveysOnClient = ({ id }) => (dispatch) => {
  const process = () => ClientsService.getSurveysOnClient(id);
  dispatch(executeAction(actionTypes.SURVEYS_BY_CLIENT, process));
};

export const editSurveysOnClient = ({ clientId, surveysList, operation }) => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.editSurveysOnClient({ clientId, surveysList, operation });
    await ClientsService.getSurveysOnClient(clientId);
    return result;
  };
  dispatch(executeAction(actionTypes.EDIT_SURVEYS_BY_CLIENT, process));
};

export const getCurrentLocationsOnClient = () => (dispatch) => {
  const process = async () => {
    const result = await ClientsService.getCurrentLocationsOnClient();
    return result;
  };
  dispatch(executeAction(actionTypes.CURRENT_CLIENTS, process));
};

export const clearClients = () => (dispatch) => {
  dispatch(executeAction(actionTypes.CLEAR_CLIENTS));
};
