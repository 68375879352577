import React from 'react';
import './style.css';
import PropTypes from 'prop-types';

import { Layout } from 'antd';

const ApplicationLayout = ({ children }) => (
  <Layout style={{ height: '100vh' }}>{children}</Layout>
);

ApplicationLayout.propTypes = {
  children: PropTypes.node,
};
export default ApplicationLayout;
