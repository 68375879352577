import { SET_USER, LOGOUT, SELECTED_CLIENT } from './types';
import { actionHandler } from '../../utils/actionHandler';
import { executeAction } from '../../utils/app/app.actions';

export const setUser = (data) => ({
  type: SET_USER,
  payload: {
    user: data.user,
    roles: data.roles,
    clients: data.clients,
    token: data.accessToken,
  },
});

export const login = (loginData) => async (dispatch) => {
  actionHandler('/auth/login', 'post', null, loginData, dispatch, setUser);
};

export const logout = () => ({
  type: LOGOUT,
});

export const actionLogout = () => (dispatch) => {
  dispatch(executeAction(LOGOUT));
};

export const setCurrentClient = (value) => async (dispatch) => {
  console.log('on Actions', value);
  const process = () => value;
  dispatch(executeAction(SELECTED_CLIENT, process));
};
