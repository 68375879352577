import {
  SET_ASSIGNMENTS,
  SET_AVAILABLE_LOCATIONS,
  REMOVE_ASSIGNMENT, CLEAR_ASSIGNMENTS, CLEAR_ASSIGNMENTS_SUCCESS,
} from './types';

const initialState = {
  data: [],
  availableLocations: [],
};

export default function assignmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ASSIGNMENTS: {
      return {
        ...state,
        data: action.payload.assignments,
      };
    }
    case SET_AVAILABLE_LOCATIONS: {
      return {
        ...state,
        availableLocations: action.payload.locations,
      };
    }
    case REMOVE_ASSIGNMENT: {
      const newAssignments = state.data.filter(
        (el) => el.id !== action.payload.id,
      );
      return {
        ...state,
        data: newAssignments,
      };
    }
    case CLEAR_ASSIGNMENTS_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
}
