import { Button, Form, Input } from 'antd';
import React from 'react';

const ClientForm = (props) => {
  const { form } = props;
  const { onFinish } = props;
  return (
  // eslint-disable-next-line react/react-in-jsx-scope
    <div className="user-form">
      <h6><b>Nuevo Cliente</b></h6>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre del cliente',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ClientForm;
