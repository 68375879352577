import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input } from 'antd';

const LocationForm = ({ handleCreateLocation }) => {
  const tailLayout = {
    wrapperCol: {
      offset: 0,
    },
  };
  const [form] = Form.useForm();

  return (
    <div className="location-form">
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={(data) => handleCreateLocation(data, form.resetFields)}
        onFinishFailed={() => {}}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre del punto de venta',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Dirección"
          name="address"
          rules={[
            {
              required: true,
              message: 'Ingresa la dirección',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Zona"
          name="zone"
          rules={[{ required: true, message: 'Ingresa la zona' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

LocationForm.propTypes = {
  handleCreateLocation: PropTypes.func,
};

export default LocationForm;
