import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Input, Modal, Row, Select, Transfer,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../../components/Layout/PageLayout';
import {
  editUsersOnClient,
  getCurrentLocationsOnClient,
  getLocationsOnClient,
} from '../../store/clients/clients.actions';
import { getUsers } from '../../store/users/actions';
import {
  selectCurrentLocations, selectLocationsOnClient,
  selectUsersOnClient2,
} from '../../store/clients/clients.selector';
import { selectCurrentToken, selectSelectedClient } from '../../store/auth/auth.selector';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { useHasErrors } from '../../utils/app/error/error.hooks';
import { actionTypes } from '../../store/tags/tags.types';
import { getTags } from '../../store/tags/tags.actions';
import TagTransfer from '../../components/Transfer/TagTransfer';
import { store } from '../../store';

const { Option } = Select;
const { error } = Modal;

const TagForm = (props) => {
  const dispatch = useDispatch();
  const state = store.getState();
  const {
    form, onFinish, setData, targetData, edit, setupData,
  } = props;

  // Selectors
  const token = useSelector(selectCurrentToken);
  const usersGet = useSelector(selectUsersOnClient2);
  const locationsGet = useSelector(selectLocationsOnClient);

  // Hooks
  const [selectedType, setSelectedType] = useState('-1');

  // state changes
  const handleSelectChange = (value) => {
    setData([]);
    const currentClient = state.auth.selectedClient;
    if (value === '0') dispatch(getUsers(token));
    if (value === '1') dispatch(getLocationsOnClient({ id: currentClient }));
    setSelectedType(value);
  };

  // Functions
  const init = () => {
    if (edit) setSelectedType(setupData.type.toString());
  };
  // useState
  useEffect(init, []);

  return (
    <div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa el nombre de la etiqueta',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {
          !edit && (
          <Form.Item name="type" label="Tipo" rules={[{ required: true }]}>
            <Select value="0" onChange={handleSelectChange}>
              <Option value="0">Usuarios</Option>
              <Option value="1">Puntos de venta</Option>
              <Option value="2" disabled>
                Etiquetas
              </Option>
            </Select>
          </Form.Item>
          )
        }
        <Row>
          <Col xs={0} sm={24}>
            {(selectedType === '0')
            && (
            <div>
              Mostrar Usuarios
              <TagTransfer type={0} data={usersGet} setData={setData} targetData={targetData} />
            </div>
            )}
            {(selectedType === '1')
            && (
            <div>
              Mostrar Puntos de venta
              <TagTransfer type={1} data={locationsGet} setData={setData} targetData={targetData} />
            </div>
            )}
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default TagForm;
