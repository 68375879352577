import { CLEAR_LOCATIONS, SET_LOCATIONS } from './types';
import { actionHandler } from '../../utils/actionHandler';
import { executeAction } from '../../utils/app/app.actions';

export const setLocations = (locations) => ({
  type: SET_LOCATIONS,
  payload: { locations },
});

export const getLocations = (token, query) => (dispatch) => {
  let url = '/locations';
  if (query && query.length > 0) url += query[0] === '&' ? `?${query.substring(1, query.length)}` : query;
  actionHandler(url, 'get', token, null, dispatch, setLocations);
};

export const createLocation = (location, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getLocations(token));
  actionHandler('/locations', 'post', token, location, dispatch, cb);
};

export const createCSVLocation = (file, token, onfinish) => (dispatch) => {
  const cb = (resultData) => (innerDispatch) => { innerDispatch(getLocations(token)); onfinish(resultData); };
  actionHandler('/locations/csv', 'post', token, file, dispatch, cb, false, false, 'multipart/form-data');
};

export const deleteLocation = (locationId, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getLocations(token));
  actionHandler(
    `/locations/${locationId}`,
    'delete',
    token,
    null,
    dispatch,
    cb,
  );
};

export const clearLocations = () => (dispatch) => {
  dispatch(executeAction(CLEAR_LOCATIONS));
};
