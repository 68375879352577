import React from 'react';
import {
  Form, Input, Button, Select,
} from 'antd';
import PropTypes from 'prop-types';

const UserForm = ({ handleCreateUser = () => {}, userRoles = [] }) => {
  const [form] = Form.useForm();
  const tailLayout = {
    wrapperCol: {
      offset: 0,
    },
  };

  return (
    <div className="user-form">
      <h6><b>Nuevo usuario</b></h6>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={(data) => handleCreateUser(data, form.resetFields)}
        onFinishFailed={() => {}}
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa nombre de usuario',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa el correo del usuario',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Rol" rules={[{ required: true }]}>
          <Select placeholder="Selecciona el rol del usuario" allowClear>
            {userRoles.map((r) => (
              <Select.Option key={r.id} value={r.id}>
                {r.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

UserForm.propTypes = {
  handleCreateUser: PropTypes.func,
  userRoles: PropTypes.array,
};

export default UserForm;
