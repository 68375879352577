import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer, persistStore } from 'redux-persist';
import { rootReducer } from './root.reducer';

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const persistConfig = {
  key: 'primary',
  version: 0,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['loading', 'error'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));
const middleWareEnhancer = applyMiddleware(thunkMiddleware, routeMiddleware);

const rootStore = createStore(
  persistedReducer,
  composeWithDevTools(middleWareEnhancer),
);

export const store = rootStore;
export const persistor = persistStore(rootStore);
