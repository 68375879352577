import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Input, Select, InputNumber, Space, Checkbox, Modal,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Conditional from '../../components/Questions/Conditional';

const QuestionForm = ({ handleCreateQuestion, options = [] }) => {
  const [valid, setValid] = useState(false);
  const [validationCalled, setValidationCalled] = useState(false);
  const [headers, setHeaders] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [optionType, setOptionType] = useState(0);
  const [showOptionType, setShowOptionType] = useState(false);
  const [single, setSingle] = useState(false);
  const [showConditional, setShowConditional] = useState(false);

  const [form] = Form.useForm();

  const validateHeaders = (description) => {
    if (!description) return false;
    /* eslint-disable no-useless-escape */
    const pattern = /.*\(([\w\sáéíóúñ]+)\,\s?([\w\sáéíóúñ]+)\,\s?([\w\sáéíóúñ]+)\)$/;
    const result = description.match(pattern);
    return result
      ? {
        option1: result[1],
        option2: result[2],
        check: result[3],
      }
      : false;
  };

  const runValidation = (data) => {
    const opts = validateHeaders(data);
    if (opts) {
      setValid(true);
      setHeaders(opts);
    } else {
      setValid(false);
      setHeaders(null);
    }
    setValidationCalled(true);
  };

  const resetFields = () => {
    setValidationCalled(false);
    form.resetFields();
  };

  const handleChange = (value) => {
    if (value === 'photo') setShowOptions(false);
    else setShowOptions(true);
    if (value === 'multiple') setShowOptionType(true);
    else setShowOptionType(false);
  };

  const toggleConditional = () => { setShowConditional(!showConditional); };

  return (
    <div className="question-form">
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={(data) => handleCreateQuestion(data, resetFields)}
        onFinishFailed={() => {}}
        style={{ height: 100, scrollBehavior: 'auto' }}
      >
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            {
              required: true,
              message: 'Descripcion es requerida',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {validationCalled && (
          <div style={{ color: valid ? 'green' : 'red', marginBottom: 15 }}>
            {valid
              ? `${headers.option1} - ${headers.option2} - ${headers.check}`
              : 'Formato de encabezado invalido'}
          </div>
        )}
        <div style={{ marginBottom: 15 }}>
          <Button
            onClick={() => runValidation(form.getFieldValue('description'))}
          >
            Validar encabezados
          </Button>
        </div>
        <Form.Item
          name="type"
          label="Tipo de respuesta"
          rules={[{ required: true, message: 'tipo de pregunta es requerido' }]}
        >
          <Select placeholder="Selecciona un tipo de respuesta." allowClear onChange={handleChange}>
            {options.map((opt, idx) => (
              <Select.Option value={opt} key={idx}>
                {opt}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        { showOptionType
          && (
          <div>
            <Form.Item
              name="optionType"
              label="Tipo de opciones"
              rules={[{ required: true, message: 'El tipo de opciones es requerido' }]}
            >
              <Select placeholder="Selecciona un tipo de opcion." onChange={(value) => { setOptionType(value); }}>
                <Select.Option value={0} key={0}>
                  Texto
                </Select.Option>
                <Select.Option value={1} key={1}>
                  Numerica
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label=""
              name="single"
              valuePropName="checked"
            >
              <Checkbox>Respuestas Unicas</Checkbox>
            </Form.Item>
          </div>
          )}
        <Form.List name="options" style={{ marginTop: 10 }}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 ? 'Opciones' : ''}
                  required
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                        message: 'Ingresa opcion',
                      },
                    ]}
                    noStyle
                  >
                    <Input placeholder="opcion" style={{ width: '60%' }} />
                  </Form.Item>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              {
                showOptions
                && (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Agregar una opción
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
                )
              }
            </>
          )}
        </Form.List>
        <Form.Item
          label={showOptions ? 'Agregar grupo de opciones (separadas por enter)' : 'Agregue una descripcion para la fotografia'}
          name="bulkOptions"
        >
          <Input.TextArea style={{ height: 200 }} />
        </Form.Item>
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Space>
      </Form>
      <Modal
        title="Pregunta condicional"
        centered
        visible={showConditional}
        okText="Agregar"
        onOk={toggleConditional}
        onCancel={toggleConditional}
        width={1000}
      >
        <Conditional />
      </Modal>
    </div>
  );
};

QuestionForm.propTypes = {
  handleCreateQuestion: PropTypes.func,
  options: PropTypes.array,
};

export default QuestionForm;
