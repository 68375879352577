import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { selectCandidateQuestions } from '../../store/surveys/surveys.selector';
import { getConditionalQuestions } from '../../store/surveys/actions';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import * as actionTypes from '../../store/surveys/types';

const ConditionQuestionModal = (props) => {
  const dispatch = useDispatch();
  const candidates = useSelector(selectCandidateQuestions);
  const { surveyId } = props;
  const { question } = props;

  return (
    <div>
      <p>Seleccione la siguiente pregunta</p>
      <Select
        showSearch
        placeholder=""
        style={{ width: '100%' }}
        onChange={(value) => { props.setNext(value); }}
      >
        {props.questions.filter((x) => x.id != props.question).map((c) => (
          <Select.Option
            key={c.id}
            value={c.id}
          >
            {c.text}
          </Select.Option>
        )) }
      </Select>
    </div>
  );
};

export default ConditionQuestionModal;
