import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import uiReducer from './ui/reducer';
import authReducer from './auth/reducer';
import usersReducer from './users/reducer';
import locationsReducer from './locations/reducer';
import surveysReducer from './surveys/reducer';
import conditionalSurveysReducer from './surveys/surveys.reducer';
import assignmentsReducer from './assignments/reducer';
import clientsReducer from './clients/clients.reducer';
import errorReducer from '../utils/app/error/error.reducer';
import loadingReducer from '../utils/app/loading/loading.reducer';
import tagsReducer from './tags/tags.reducer';

export const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ui: uiReducer,
  error: errorReducer,
  loading: loadingReducer,
  auth: authReducer,
  users: usersReducer,
  locations: locationsReducer,
  surveys: surveysReducer,
  assignments: assignmentsReducer,
  clients: clientsReducer,
  tags: tagsReducer,
  conditionalSurveys: conditionalSurveysReducer,
});
