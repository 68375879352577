import { CLEAR_LOCATIONS, SET_LOCATIONS } from './types';
import { successState } from '../../utils/app/app.actions';

const initialState = {
  data: [],
};

export default function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATIONS: {
      return {
        ...state,
        data: action.payload.locations,
      };
    }
    case successState(CLEAR_LOCATIONS):
      return initialState;
    default: {
      return state;
    }
  }
}
