import { actionHandler } from '../../utils/actionHandler';
import { SET_USERS, SET_ROLES, CLEAR_USERS } from './types';
import { executeAction } from '../../utils/app/app.actions';

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: { users },
});

export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: { roles },
});

export const getUsers = (token) => (dispatch) => {
  actionHandler('/users/with-roles', 'get', token, null, dispatch, setUsers);
};

export const addUserRole = (userId, role, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getUsers(token));
  actionHandler(`/users/${userId}/roles`, 'put', token, role, dispatch, cb);
};

export const removeUserRole = (userId, role, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getUsers(token));
  actionHandler(`/users/${userId}/roles`, 'delete', token, role, dispatch, cb);
};

export const createUser = (user, token, role, onSuccess) => (dispatch) => {
  const callback = (data) => (innerDispatch) => {
    onSuccess(`Password ${data.password}`, `Nuevo usuario creado ${data.user.email}`);
    innerDispatch(addUserRole(data.user.id, role, token));
  };
  actionHandler('/users', 'post', token, user, dispatch, callback);
};

export const disableUser = (userId, token) => (dispatch) => {
  const cb = () => (innerDispatch) => {
    innerDispatch(getUsers(token));
  };
  actionHandler(`/users/${userId}`, 'delete', token, null, dispatch, cb);
};

export const getRoles = (token) => (dispatch) => {
  actionHandler('/roles', 'get', token, null, dispatch, setRoles);
};

export const resetPassword = (userId, token, onSuccess) => (dispatch) => {
  const callback = (data) => {
    onSuccess(`Password ${data.password}`, `Contraseña restablecida: ${data.user.email}`);
  };
  actionHandler(
    `/users/${userId}/reset-password`,
    'patch',
    token,
    null,
    dispatch,
    callback,
  );
};

export const clearUsers = () => (dispatch) => {
  dispatch(executeAction(CLEAR_USERS));
};
