import { $http } from '../../utils/http.utils';

class TagsService {
    getTags = () =>
        $http.get(`/tag`);

    getTag = (id) =>
        $http.get(`/tag/${id}`);

    getTagsForItem = ({itemId,type}) =>
        $http.get(`/tag/${type}/${itemId}`);

    getItemsForTagList = ({items,type}) =>
        $http.post(`/tag/itemList/${type}`, items);

    addTagsOnItem = ({itemId,items,type}) =>
        $http.post(`/tag/list/${itemId}/${type}`,items);

    removeTagsOnItem = ({itemId,items,type}) =>
        $http.post(`/tag/list/remove/${itemId}/${type}`,items);

    createTag =
        ({ name, items, type }) =>
            $http.post('/tag', { name,items,type });

    editTag =
        ({ id, name, items }) =>
            $http.put(`/tag/${id}`, { name, items });

    deleteTag  =
        (tagId) =>
            $http.delete(`/tag/${tagId}`);

    editQuestion =
        ({ text , surveyId, questionId }) =>
            $http.put(`/surveys/${surveyId}/questions/text/${questionId}`, {name:text});


    assignByList =
        ({ surveyId, usersList, locationsList }) =>
            $http.post(`/assignments/byList`, {surveyId, usersList, locationsList});

}
export default new TagsService();
