import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

const { Content, Sider } = Layout;

const PageLayout = ({
  sider, children, title, topBar,
}) => (
  <Layout>
    {sider && (
      <Sider width={350} className="site-layout-background">
        <div style={{ height: '100%', overflowY: 'auto', padding: 20 }}>
          {sider}
        </div>
      </Sider>
    )}
    <Layout style={{ padding: '0 24px 24px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><h4><b>{title}</b></h4></Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          { topBar || '' }
          {children}
        </div>
      </Content>
    </Layout>
  </Layout>
);

PageLayout.propTypes = {
  sider: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default PageLayout;
