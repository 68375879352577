import axios from 'axios';

export const apiUrl = 'https://admin.instore.services/api';
// export const apiUrl = 'http://localhost:8080/api';
const timeout = 1000 * 25;
const baseURL = apiUrl;
const instance = axios.create({
  timeout,
  baseURL,
});

export default instance;

export const downloadFile = async (url, headers, params) => {
  const response = await instance.get(url, { responseType: 'blob', headers: { accept: 'application/octet-stream', ...headers }, params });
  const { data } = response;
  if (!(data instanceof Blob)) return;

  const filename = response.headers['x-file-name'];
  const blob = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};
