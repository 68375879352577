import { successState } from '../../utils/app/app.actions';
import { CANDIDATE_QUESTIONS, SET_CONDITION, UNLINK_CONDITION } from './types';

const initialState = {
  candidateQuestions: [],
  conditionQuestion: {},
  unLinkCondition: {},
};

const conditionalSurveysReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(CANDIDATE_QUESTIONS):
      return {
        ...state,
        candidateQuestions: data,
      };
    case successState(SET_CONDITION):
      return {
        ...state,
        conditionQuestion: data,
      };
    case successState(UNLINK_CONDITION):
      return {
        ...state,
        unLinkCondition: data,
      };
    default:
      return state || initialState;
  }
};

export default conditionalSurveysReducer;
