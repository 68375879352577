import {
  SET_SURVEYS, SET_SURVEY, SET_SURVEY_QUESTIONS, SET_OPTIONS, CLEAR_SURVEYS,
} from './types';
import { successState } from '../../utils/app/app.actions';

const initialState = {
  data: [],
  selectedSurvey: null,
  selectedSurveyQuestions: [],
  options: [],
};

export default function surveysReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SURVEYS: {
      return {
        ...state,
        data: action.payload.surveys,
      };
    }
    case SET_SURVEY: {
      return {
        ...state,
        selectedSurvey: action.payload.survey,
      };
    }
    case SET_SURVEY_QUESTIONS: {
      return {
        ...state,
        selectedSurveyQuestions: action.payload.questions,
      };
    }
    case SET_OPTIONS: {
      return {
        ...state,
        options: action.payload.options,
      };
    }
    case successState(CLEAR_SURVEYS):
      return initialState;
    default: {
      return state;
    }
  }
}
