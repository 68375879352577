export const actionTypes = {
  GET_ALL_TAGS: 'GET_ALL_TAGS',
  GET_TAG: 'GET_TAG',
  GET_TAGS_FOR_ITEM: 'GET_TAGS_FOR_ITEM',
  GET_ITEMS_FOR_TAG_LIST_0: 'GET_ITEMS_FOR_TAG_LIST_0',
  GET_ITEMS_FOR_TAG_LIST_1: 'GET_ITEMS_FOR_TAG_LIST_1',
  ADD_TAG_TO_ITEM: 'ADD_TAG_TO_ITEM',
  REMOVE_TAG_TO_ITEM: 'REMOVE_TAG_TO_ITEM',
  CREATE_TAG: 'CREATE_TAG',
  DELETE_TAG: 'DELETE_TAG',
  EDIT_TAG: 'EDIT_TAG',
  EDIT_QUESTION: 'EDIT_QUESTION',
  ASSIGN_BY_LIST: 'ASSIGN_BY_LIST',
};
