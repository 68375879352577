import { LOGOUT_SUCCESS, SELECTED_CLIENT_SUCCESS, SET_USER } from './types';

const initialState = {
  currentUser: null,
  auth: null,
  router: null,
  ui: null,
  selectedClient: null,
};

export default function authReducer(state = initialState, { type, payload, data } = {}) {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        currentUser: payload,
      };

    case LOGOUT_SUCCESS:
      return initialState;
      // return {
      //   ...state,
      //   currentUser: null,
      // };

    case SELECTED_CLIENT_SUCCESS:
      console.log('onReducer', data);
      return {
        ...state,
        selectedClient: data,
      };
    default: {
      return state;
    }
  }
}
