import {
  CANDIDATE_QUESTIONS,
  CLEAR_SURVEYS, SET_CONDITION,
  SET_OPTIONS, SET_SURVEY, SET_SURVEY_QUESTIONS, SET_SURVEYS,
} from './types';
import { actionHandler } from '../../utils/actionHandler';
import { executeAction } from '../../utils/app/app.actions';
import SurveysService from './surveys.service';

export const setSurveys = (surveys) => ({
  type: SET_SURVEYS,
  payload: { surveys },
});

export const selectSurvey = (survey) => ({
  type: SET_SURVEY,
  payload: { survey },
});

export const setSurveyQuestions = (questions) => ({
  type: SET_SURVEY_QUESTIONS,
  payload: { questions },
});

export const getSurveys = (token) => (dispatch) => {
  actionHandler('/surveys', 'get', token, null, dispatch, setSurveys);
};

export const createSurvey = (survey, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getSurveys(token));
  actionHandler('/surveys', 'post', token, survey, dispatch, cb);
};

export const getSurveyQuestions = (surveyId, token) => (dispatch) => {
  actionHandler(
    `/surveys/${surveyId}/questions`,
    'get',
    token,
    null,
    dispatch,
    setSurveyQuestions,
  );
};

export const createSurveyQuestion = (surveyId, question, token) => (
  dispatch,
) => {
  const cb = () => (innerDispatch) => innerDispatch(getSurveyQuestions(surveyId, token));
  actionHandler(
    `/surveys/${surveyId}/questions`,
    'post',
    token,
    question,
    dispatch,
    cb,
  );
};

export const deleteSurveyQuestion = (surveyId, questionId, token) => (
  dispatch,
) => {
  const cb = () => (innerDispatch) => innerDispatch(getSurveyQuestions(surveyId, token));
  actionHandler(
    `/surveys/${surveyId}/questions/${questionId}`,
    'delete',
    token,
    null,
    dispatch,
    cb,
  );
};

export const deleteSurvey = (surveyId, token) => (dispatch) => {
  const cb = (data) => (innerDispatch) => {
    innerDispatch(getSurveys(token));
    innerDispatch(selectSurvey(data));
  };
  actionHandler(`/surveys/${surveyId}`, 'delete', token, null, dispatch, cb);
};

export const enableSurvey = (surveyId, token) => (dispatch) => {
  const cb = (data) => (innerDispatch) => {
    innerDispatch(getSurveys(token));
    innerDispatch(selectSurvey(data));
  };
  actionHandler(`/surveys/${surveyId}`, 'patch', token, null, dispatch, cb);
};

export const setSurveyOptions = (options) => ({
  type: SET_OPTIONS,
  payload: { options },
});

export const getSurveyOptions = (token) => (dispatch) => {
  const cb = (data) => (innerDispatch) => {
    innerDispatch(setSurveyOptions(data));
  };
  actionHandler(
    '/surveys/question-types',
    'get',
    token,
    null,
    dispatch,
    cb,
  );
};

export const downloadReport = (surveyId, assignmentId, token) => (dispatch) => {
  const cb = () => () => {};
  actionHandler(`/surveys/${surveyId}/answers/report/${assignmentId}`, 'get', token, null, dispatch, cb, false, true);
};

export const deleteOption = (surveyId, questionId, optionId, token) => (dispatch) => {
  const cb = () => (innerDispatch) => {
    innerDispatch(getSurveyQuestions(surveyId, token));
  };
  actionHandler(`/surveys/${surveyId}/questions/${questionId}/options/${optionId}`, 'delete', token, null, dispatch, cb);
};

export const copySurvey = (surveyId, surveyCopyName, token) => (dispatch) => {
  const cb = () => (innerDispatch) => {
    innerDispatch(getSurveys(token));
  };
  actionHandler(`/surveys/${surveyId}/copy`, 'post', token, { name: surveyCopyName }, dispatch, cb);
};

export const createOption = (surveyId, questionId, text, token) => (dispatch) => {
  const cb = () => (innerDispatch) => {
    innerDispatch(getSurveyQuestions(surveyId, token));
  };
  actionHandler(`/surveys/${surveyId}/questions/${questionId}/options`, 'post', token, { text }, dispatch, cb);
};

export const clearSurveys = () => (dispatch) => {
  dispatch(executeAction(CLEAR_SURVEYS));
};

export const getConditionalQuestions = ({ surveyId, question }) => (dispatch) => {
  const process = () => SurveysService.getConditionalQuestions({ surveyId, question });
  dispatch(executeAction(CANDIDATE_QUESTIONS, process));
};

export const setConditionalQuestion = ({
  surveyId, questionId, optionId, conditionId,
}) => (dispatch) => {
  const process = () => SurveysService.setConditionalQuestion({
    surveyId, questionId, optionId, conditionId,
  });
  dispatch(executeAction(SET_CONDITION, process));
};

export const unLinkConditionalQuestion = ({
  surveyId, questionId, optionId, conditionId,
}) => (dispatch) => {
  const process = () => SurveysService.unLinkConditionalQuestion({
    surveyId, questionId, optionId, conditionId,
  });
  dispatch(executeAction(SET_CONDITION, process));
};
