import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal } from 'antd';

const SurveysPanel = ({
  surveys, handleDisableSurvey, handleSelectSurvey, handleCopySurvey,
}) => {
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [surveyCopyName, setSurveyCopyName] = useState('');
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const handleCopyOk = () => {
    handleCopySurvey(selectedSurvey, surveyCopyName);
    setShowCopyModal(false);
    setSurveyCopyName('');
    setSelectedSurvey(null);
  };

  const handleCopyCancel = () => {
    setShowCopyModal(false);
    setSurveyCopyName('');
    setSelectedSurvey(null);
  };

  const handleDeleteOk = () => {
    handleDisableSurvey(selectedSurvey);
    setShowDeleteModal(false);
    setSelectedSurvey(null);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setSelectedSurvey(null);
  };

  const handleShowCopyModal = (surveyId) => {
    setShowCopyModal(true);
    setSelectedSurvey(surveyId);
  };

  const handleShowDeleteModal = (surveyId) => {
    setShowDeleteModal(true);
    setSelectedSurvey(surveyId);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions) => (
        <>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => handleSelectSurvey(actions.id)}
          >
            Ver
          </Button>
          <Button 
            danger 
            onClick={() => handleShowDeleteModal(actions.id)}
          >
            Eliminar
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleShowCopyModal(actions.id)}
          >
            copiar
          </Button>
        </>
      ),
    },
  ];

  const dataSource = surveys.map((s) => ({
    ...s,
    key: s.id,
    actions: { deleted: s.deleted, id: s.id },
  }));

  return (
    <div>
      {/* Copy Survey Modal */}
      <Modal
        title="Copiar encuesta"
        visible={showCopyModal}
        onOk={handleCopyOk}
        onCancel={handleCopyCancel}
        okText="Copiar"
        cancelText="Cancelar"
      >
        <p>Nombre de la nueva encuesta</p>
        <input
          value={surveyCopyName}
          onChange={(e) => setSurveyCopyName(e.target.value)}
          style={{ width: '100%' }}
        />
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirmar"
        visible={showDeleteModal}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <p>¿Está seguro que desea eliminar esta encuesta?</p>
      </Modal>

      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

SurveysPanel.propTypes = {
  surveys: PropTypes.array,
  handleSelectSurvey: PropTypes.func,
  handleDisableSurvey: PropTypes.func,
  handleCopySurvey: PropTypes.func,
};

export default SurveysPanel;