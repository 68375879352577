import { successState } from '../../utils/app/app.actions';
import { actionTypes } from './clients.types';

const initialState = {
  clients: [],
  client: {},
  clientDeleted: false,
  usersClient: {},
  locationsClient: [],
  surveysClient: [],
  data: [],
  locations: [],
};

const clientsReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.GET_ALL_CLIENTS):
      return {
        ...state,
        clients: data,
      };
    case successState(actionTypes.CREATE_CLIENT):
      return {
        ...state,
        client: data,
      };
    case successState(actionTypes.DELETE_CLIENT):
      return {
        ...state,
        clientDeleted: data,
      };
    case successState(actionTypes.CURRENT_CLIENTS):
      return {
        ...state,
        locations: data,
      };
    case successState(actionTypes.USERS_BY_CLIENT):
    case successState(actionTypes.EDIT_USERS_BY_CLIENT):
      return {
        ...state,
        usersClient: data,
      };
    case successState(actionTypes.LOCATIONS_BY_CLIENT):
    case successState(actionTypes.EDIT_LOCATIONS_BY_CLIENT):
      return {
        ...state,
        locationsClient: data,
      };
    case successState(actionTypes.SURVEYS_BY_CLIENT):
    case successState(actionTypes.EDIT_SURVEYS_BY_CLIENT):
      return {
        ...state,
        surveysClient: data,
      };
    case successState(actionTypes.CLEAR_CLIENTS):
    {
      return initialState;
    }
    default:
      return state || initialState;
  }
};

export default clientsReducer;
