import { $http } from '../../utils/http.utils'
class ClientsService {
    getClients = () =>
        $http.get(`/clients`);

    createClient =
        ({ name, }) =>
            $http.post('/clients', { name });

    deleteClient =
        (id) =>
            $http.delete(`/clients/${id}`);

    getUsersOnClient =
        (id) =>
            $http.get(`/clients/users/${id}`);

    editUsersOnClient =
        ({clientId, userList,operation}) =>
            $http.post(`/clients/users/${operation}/${clientId}`,userList);

    getLocationsOnClient =
        (id) =>
            $http.get(`/clients/pdv/${id}`);

    editLocationsOnClient =
        ({clientId, locationsList,operation}) =>
            $http.post(`/clients/pdv/${operation}/${clientId}`,locationsList);

    getSurveysOnClient =
        (id) =>
            $http.get(`/clients/surveys/${id}`);

    getCurrentLocationsOnClient =
        () =>
            $http.get(`/locations`);

    editSurveysOnClient =
        ({clientId, surveysList, operation}) =>
            $http.post(`/clients/surveys/${operation}/${clientId}`,surveysList);
}

export default new ClientsService()