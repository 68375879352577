import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSurveys,
  createSurvey,
  selectSurvey,
  getSurveyQuestions,
  createSurveyQuestion,
  deleteSurvey,
  copySurvey,
} from '../../store/surveys/actions';
import SurveyForm from './SurveyForm';
import SurveysPanel from './SurveysPanel';
import PageLayout from '../../components/Layout/PageLayout';

export const Surveys = ({
  token = '',
  surveys = [],
  onGetSurveys = () => {},
  onCreateSurvey = () => {},
  onDeleteSurvey = () => {},
  onCopySurvey = () => {},
}) => {
  const history = useHistory();

  useEffect(() => {
    onGetSurveys(token);
  }, [onGetSurveys, token]);

  const handleCreateSurvey = async (surveyData, clearSurvey) => {
    await onCreateSurvey(surveyData, token);
    clearSurvey();
  };

  const handleSelectSurvey = async (id) => {
    history.push(`/survey/${id}`);
  };

  const handleDeleteSurvey = (surveyId) => {
    onDeleteSurvey(surveyId, token);
  };

  const filterSurveys = surveys.filter((s) => !s.deleted);

  const handleCopySurvey = (surveyId, name) => {
    onCopySurvey(surveyId, name, token);
  };

  return (
    <PageLayout
      sider={(
        <SurveyForm
          handleCreateSurvey={handleCreateSurvey}
          handleSelectSurvey={handleSelectSurvey}
        />
      )}
      title="Encuestas"
    >
      <SurveysPanel
        surveys={filterSurveys}
        handleSelectSurvey={handleSelectSurvey}
        handleDisableSurvey={handleDeleteSurvey}
        handleCopySurvey={handleCopySurvey}
      />
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  surveys: state.surveys.data,
  token: state.auth.currentUser.token,
  selectedSurvey: state.surveys.selectedSurvey,
});

const mapDispatchToProps = {
  onGetSurveys: getSurveys,
  onCreateSurvey: createSurvey,
  onSelectSurvey: selectSurvey,
  onGetSurveyQuestions: getSurveyQuestions,
  onCreateQuestion: createSurveyQuestion,
  onDeleteSurvey: deleteSurvey,
  onCopySurvey: copySurvey,
};

Surveys.propTypes = {
  token: PropTypes.string,
  surveys: PropTypes.array,
  onGetSurveys: PropTypes.func,
  onCreateSurvey: PropTypes.func,
  onDeleteSurvey: PropTypes.func,
  onCopySurvey: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
