import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import Login from '../../../pages/Authentication/Login/Login';

export const RequireLoggedInUser = ({ children = {}, currentUser }) => {
  if (currentUser) return children;
  return <Login />;
};

RequireLoggedInUser.propTypes = {
  children: PropTypes.object,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(RequireLoggedInUser);
