import { CLEAR_USERS, SET_ROLES, SET_USERS } from './types';
import { successState } from '../../utils/app/app.actions';

const initialState = {
  data: [],
  roles: [],
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS: {
      return {
        ...state,
        data: action.payload.users,
      };
    }
    case SET_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
      };
    }
    case successState(CLEAR_USERS):
      return initialState;
    default: {
      return state;
    }
  }
}
