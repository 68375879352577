import React, { useState } from 'react';
import {
  Upload, Button, message, Col, Row, Table,
} from 'antd';
import { UploadOutlined, DownloadOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';

const showUploadList = {
  showPreviewIcon: false,
  showRemoveIcon: false,
  showDownloadIcon: false,
  removeIcon: false,
  downloadIcon: false,
};

const columns = [
  {
    title: 'Linea',
    dataIndex: 'line',
  },
  {
    title: 'Columna',
    dataIndex: 'column',
  },
  {
    title: 'Error',
    dataIndex: 'message',
  },
];

const LocationFileUpload = ({ handleCreateCSVLocation }) => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [showPanel, setShowPanel] = useState(false);
  const [resultMessage, setResultMessage] = useState('Archivo cargado con exito');
  const [data, setData] = useState([]);

  const props = {
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      if (!file.name.includes('.xlsx')) {
        message.error(`${file.name} no es un archivo valido`);
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFinish = (result) => {
    setShowPanel(true);
    if (result.ok) {
      setResultMessage(result.message);
      setFileList([]);
      setShowError(false);
      setShowSuccess(true);
      setData([]);
    } else {
      setFileList([]);
      setShowError(true);
      setShowSuccess(false);
      const nData = [];
      result.errors.map((err, index) => {
        nData.push({
          key: index,
          line: err.line,
          column: err.column,
          error: err.error,
          message: err.message,
        });
        return true;
      });
      setData(nData);
    }
  };

  return (
    <div>
      <div style={{ height: '20px' }} />
      <Row>
        <Col>
          <p>
            Puedes Agregar un archivo tipo .XLSX
            para agregar puntos de venta de forma masiva.
          </p>
        </Col>
      </Row>
      <div style={{ height: '20px' }} />
      <Row>
        <Col span={12}>
          <a href="https://instore-template-files.s3.amazonaws.com/Ejemplo.xlsx"><Button icon={<DownloadOutlined />}>XLSX Ejemplo</Button></a>
        </Col>
        <Col span={12}>
          <Upload
            {...props}
            fileList={fileList}
            showUploadList={showUploadList}
            customRequest={(file) => handleCreateCSVLocation(file, onFinish)}
            accept=".xlsx"
          >
            <Button icon={<UploadOutlined />}>Subir Archivo</Button>
          </Upload>
        </Col>
      </Row>
      <hr />
      <div style={{ height: '20px' }} />
      {showPanel && showError
      && (
      <div>
        <h5>Errores</h5>
        <Row>
          <Col span={24}>
            <Table columns={columns} dataSource={data} size="small" />
          </Col>
        </Row>
      </div>
      )}
      {
        showPanel && showSuccess
        && (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <p>{resultMessage}</p>
          <CheckCircleTwoTone style={{ fontSize: '26px' }} twoToneColor="#52c41a" />
        </div>
        )
      }

    </div>

  );
};

LocationFileUpload.propTypes = {
  handleCreateCSVLocation: PropTypes.func,
};

export default LocationFileUpload;
