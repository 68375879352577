import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Table, Form, Input, Select, DatePicker, Row, Col, Modal,
} from 'antd';
import {
  CloseOutlined,
  DownloadOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import { SearchOutlined } from '@material-ui/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  getFinishedAssignments,
  rejectFinishedAssignment,
  deleteAssignment,
} from '../../store/assignments/actions';
import PageLayout from '../../components/Layout/PageLayout';
import { downloadReport, getSurveys } from '../../store/surveys/actions';
import { getUsers } from '../../store/users/actions';
import { getLocations } from '../../store/locations/actions';

const Reports = ({
  onDownloadReport,
  onGetFinishedAssignments,
  onGetSurveys,
  onRejectFinishedAssignment,
  onGetUsers,
  onGetLocations,
  token,
  assignments = [],
  onDeleteAssignment,
  users = [],
  locations = [],
}) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [, forceUpdate] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);

  useEffect(() => {
    onGetFinishedAssignments(token);
    onGetSurveys(token);
    onGetUsers(token);
    onGetLocations(token);
  }, [token, onGetFinishedAssignments, onGetSurveys, onGetUsers, onGetLocations]);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleDeleteClick = (assignmentId) => {
    setSelectedAssignmentId(assignmentId);
    setDeleteModalVisible(true);
  };

  const handleRejectClick = (assignmentId) => {
    setSelectedAssignmentId(assignmentId);
    setRejectModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    onDeleteAssignment(selectedAssignmentId, token);
    setDeleteModalVisible(false);
    setSelectedAssignmentId(null);
  };

  const handleRejectConfirm = () => {
    onRejectFinishedAssignment(selectedAssignmentId, token);
    setRejectModalVisible(false);
    setSelectedAssignmentId(null);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setSelectedAssignmentId(null);
  };

  const handleRejectCancel = () => {
    setRejectModalVisible(false);
    setSelectedAssignmentId(null);
  };

  const handleFilterChange = (changedValues, allValues) => {
    let query = '';

    if (allValues.surveyName && allValues.surveyName.length > 2) {
      query += `&surveyName=${encodeURIComponent(allValues.surveyName)}`;
    }

    if (allValues.location) {
      query += `&location=${allValues.location}`;
    }

    if (allValues.user) {
      query += `&user=${allValues.user}`;
    }

    if (allValues.date) {
      query += `&sDate=${allValues.date[0].valueOf()}`;
      query += `&eDate=${allValues.date[1].valueOf()}`;
    }

    onGetFinishedAssignments(token, query);
  };

  const colsData = assignments.map((assignment) => ({
    key: assignment.id,
    surveyName: assignment.survey.name,
    locationName: assignment.location.name,
    userName: assignment.user.email,
    actions: {
      surveyId: assignment.survey.id,
      assignmentId: assignment.id,
    },
    finishedAt: assignment.finishedAt,
  }));

  const cols = [
    {
      title: 'Encuesta',
      dataIndex: 'surveyName',
      key: 'surveyName',
    },
    {
      title: 'PDV',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    {
      title: 'Usuario',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Fecha',
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      render: (val) => {
        const date = new Date(val);

        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return <div>{date.toLocaleDateString('es-ES', options)}</div>;
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions) => (
        <div key={actions.assignmentId}>
          <Row gutter={[8, 8]}>
            <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => onDownloadReport(actions.surveyId, actions.assignmentId, token)}
              >
                Descargar
              </Button>
            </Col>
            <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{
                  color: 'orange',
                  borderColor: 'orange',
                }}
                icon={<ExclamationOutlined />}
                onClick={() => handleRejectClick(actions.assignmentId)}
              >
                Rechazar
              </Button>
            </Col>
            <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                danger
                icon={<CloseOutlined />}
                onClick={() => handleDeleteClick(actions.assignmentId)}
              >
                Eliminar
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const searchBar = () => (
    <div>
      <Form form={form} onValuesChange={handleFilterChange} layout="inline">
        <Form.Item
          name="surveyName"
          style={{ minWidth: '250px' }}
        >
          <Input prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="Nombre de la encuesta" allowClear />
        </Form.Item>

        <Form.Item
          name="location"
          style={{ minWidth: '250px' }}
        >
          <Select
            showSearch
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            placeholder="PDV"
            allowClear
          >
            {locations.map((l) => (
              <Select.Option
                key={l.id}
                value={l.id}
              >
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="user"
          style={{ minWidth: '250px' }}
        >
          <Select
            showSearch
            placeholder="Usuario"
            allowClear
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input
                .toLowerCase()) >= 0}
          >
            {users.map((u) => (
              <Select.Option key={u.id} value={u.id}>
                {u.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="date"
        >
          <RangePicker locale={locale} />
        </Form.Item>

      </Form>
      <div style={{ height: '10px' }} />
    </div>
  );

  return (
    <PageLayout topBar={searchBar()} title="Reportes">
      <div style={{ height: '10px' }} />
      <Table columns={cols} dataSource={colsData} scroll={{ y: '50vh' }} />
      <Modal
        title="Confirmar"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Eliminar"
        cancelText="Cancelar"
        okButtonProps={{ danger: true }}
      >
        <p>¿Está seguro que desea eliminar?</p>
      </Modal>

      <Modal
        title="Confirmar"
        visible={rejectModalVisible}
        onOk={handleRejectConfirm}
        onCancel={handleRejectCancel}
        okText="Rechazar"
        cancelText="Cancelar"
        okButtonProps={{ style: { backgroundColor: 'orange', borderColor: 'orange' } }}
      >
        <p>¿Está seguro que desea rechazar?</p>
      </Modal>
    </PageLayout>
  );
};

Reports.propTypes = {
  token: PropTypes.string,
  assignments: PropTypes.array,
  onGetFinishedAssignments: PropTypes.func,
  onGetSurveys: PropTypes.func,
  onDownloadReport: PropTypes.func,
  onRejectFinishedAssignment: PropTypes.func,
  onDeleteAssignment: PropTypes.func,
  onGetLocations: PropTypes.func,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: state.auth.currentUser.token,
  assignments: state.assignments.data,
  users: state.users.data,
  locations: state.locations.data,
});

export default connect(mapStateToProps, {
  onGetFinishedAssignments: getFinishedAssignments,
  onGetSurveys: getSurveys,
  onDownloadReport: downloadReport,
  onRejectFinishedAssignment: rejectFinishedAssignment,
  onDeleteAssignment: deleteAssignment,
  onGetUsers: getUsers,
  onGetLocations: getLocations,
})(Reports);
