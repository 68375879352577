import TagsService from './tags.service';
import { executeAction } from '../../utils/app/app.actions';
import { actionTypes } from './tags.types';

export const getTags = () => (dispatch) => {
  const process = () => TagsService.getTags();
  dispatch(executeAction(actionTypes.GET_ALL_TAGS, process));
};

export const getTag = (id) => (dispatch) => {
  const process = () => TagsService.getTag(id);
  dispatch(executeAction(actionTypes.GET_TAG, process));
};

export const getTagsForItem = ({ itemId, type }) => (dispatch) => {
  const process = () => TagsService.getTagsForItem({ itemId, type });
  dispatch(executeAction(actionTypes.GET_TAGS_FOR_ITEM, process));
};

export const getItemsForTagList = ({ items, type }) => (dispatch) => {
  const process = () => TagsService.getItemsForTagList({ items, type });
  dispatch(executeAction(type === 0
    ? actionTypes.GET_ITEMS_FOR_TAG_LIST_0
    : actionTypes.GET_ITEMS_FOR_TAG_LIST_1, process));
};

export const addTagOnItem = ({ itemId, items, type }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.addTagsOnItem({ itemId, items, type });
    await TagsService.getTagsForItem({ itemId, type });
    return result;
  };
  dispatch(executeAction(actionTypes.ADD_TAG_TO_ITEM, process));
};

export const createTag = ({ name, items, type }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.createTag({ name, items, type });
    await TagsService.getTags();
    return result;
  };
  dispatch(executeAction(actionTypes.CREATE_TAG, process));
};

export const editTag = ({ id, name, items }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.editTag({ id, name, items });
    await TagsService.getTags();
    return result;
  };
  dispatch(executeAction(actionTypes.EDIT_TAG, process));
};

export const deleteTag = (tagId) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.deleteTag(tagId);
    await TagsService.getTags();
    return result;
  };
  dispatch(executeAction(actionTypes.DELETE_TAG, process));
};

export const removeTagOnItem = ({ itemId, items, type }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.removeTagsOnItem({ itemId, items, type });
    await TagsService.getTagsForItem({ itemId, type });
    return result;
  };
  dispatch(executeAction(actionTypes.ADD_TAG_TO_ITEM, process));
};

export const editQuestion = ({ text, surveyId, questionId }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.editQuestion({ text, surveyId, questionId });
    return result;
  };
  dispatch(executeAction(actionTypes.EDIT_QUESTION, process));
};

export const assignByList = ({ surveyId, usersList, locationsList }) => (dispatch) => {
  const process = async () => {
    const result = await TagsService.assignByList({ surveyId, usersList, locationsList });
    return result;
  };
  dispatch(executeAction(actionTypes.ASSIGN_BY_LIST, process));
};
