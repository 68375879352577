import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, Modal, Row, Space, Table, Tooltip,
} from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import PageLayout from '../../components/Layout/PageLayout';
import ClientForm from '../Clients/ClientForm';
import TagForm from './TagForm';
import { selectLocationsOnClient, selectUsersOnClient } from '../../store/clients/clients.selector';
import { createClient, getClients } from '../../store/clients/clients.actions';
import { getUsers } from '../../store/users/actions';
import {
  createTag, deleteTag, editTag, getTag, getTags,
} from '../../store/tags/tags.actions';
import { selectCurrentToken } from '../../store/auth/auth.selector';
import { selectTag, selectTags } from '../../store/tags/tags.selector';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { actionTypes } from '../../store/tags/tags.types';
import { useHasErrors } from '../../utils/app/error/error.hooks';

const Tags = () => {
  const { confirm, error } = Modal;
  const dispatch = useDispatch();

  const tags = useSelector(selectTags);
  const selectedTag = useSelector(selectTag);

  // Hooks
  const [form] = Form.useForm();
  const [tagModal, setTagModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [targetData, setTargetData] = useState([]);

  const [createIsLoading, createIsFinished] = useIsLoading([actionTypes.CREATE_TAG]);
  const [createError, createHasError] = useHasErrors([actionTypes.CREATE_TAG]);

  const [deleteIsLoading, deleteIsFinished] = useIsLoading([actionTypes.DELETE_TAG]);
  const [deleteError, deleteHasError] = useHasErrors([actionTypes.CREATE_TAG]);

  const [getTagIsLoading, getTagIsFinished] = useIsLoading([actionTypes.GET_TAG]);
  const [getTagError, getTagHasError] = useHasErrors([actionTypes.GET_TAG]);

  const [editTagIsLoading, editTagIsFinished] = useIsLoading([actionTypes.EDIT_TAG]);
  const [editTagError, editTagHasError] = useHasErrors([actionTypes.EDIT_TAG]);

  // Functions
  const init = () => {
    dispatch(getTags());
  };
  const setData = (data) => {
    setTargetData(data);
  };

  const onFinish = async ({ name, type }) => {
    console.log('Test');
    console.log(name);
    console.log(type);
    console.log(targetData);
    dispatch(createTag({ name, items: targetData, type: parseInt(type) }));
    setTagModal(false);
    form.resetFields();
    setTargetData([]);
  };

  const onEdit = async ({ name }) => {
    console.log('Test');
    console.log(name);
    console.log(selectedTag.type);
    console.log(targetData);
    dispatch(editTag({ id: selectedTag.tagId, name, items: targetData }));
    setEditModal(false);
    form.resetFields();
    setTargetData([]);
  };

  const handleDeleteTag = async (tagId) => {
    confirm({
      title: 'Desea eliminar esta etiqueta?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(deleteTag(tagId));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleEdit = async (tagId) => {
    dispatch(getTag(tagId));
  };

  // useEffect
  useEffect(init, []);

  useEffect(() => {
    if (createIsFinished) {
      if (createHasError) {
        error({
          title: '¡Uh-oh!',
          content: createError.message || 'Ocurrio un error inesperado',
        });
      } else {
        dispatch(getTags());
      }
    }
  }, [createIsLoading, createHasError]);

  useEffect(() => {
    if (deleteIsFinished) {
      if (!deleteHasError) {
        dispatch(getTags());
      }
    }
  }, [deleteIsLoading, deleteHasError]);

  useEffect(() => {
    if (getTagIsFinished) {
      if (!getTagHasError) {
        form.setFieldsValue({ name: selectedTag.tagText, type: selectedTag.type.toString() });
        switch (selectedTag.type) {
          case 0:
            setTargetData(selectedTag.users.map((u) => u.id));
            break;
          case 1:
            setTargetData(selectedTag.locations.map((u) => u.id));
            break;
        }

        setEditModal(true);
      }
    }
  }, [getTagIsLoading, getTagHasError]);

  useEffect(() => {
    if (editTagIsFinished) {
      if (!editTagHasError) {
        dispatch(getTags());
      } else {
        error({
          title: '¡Uh-oh!',
          content: editTagError.message || 'Ocurrio un error inesperado',
        });
      }
    }
  }, [editTagIsLoading, editTagHasError]);

  const cols = [
    {
      title: '',
      dataIndex: 'logo',
      key: 'logo',
    },
    {
      title: 'Nombre',
      dataIndex: 'tagText',
      key: 'tagText',
      render: (tagText) => (
        <div style={{ textTransform: 'capitalize' }}>{tagText}</div>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <>
          <Row xs={0} sm={12}>
            <Col xs={0} sm={12}>
              <Button
                style={{ marginLeft: 5 }}
                onClick={() => handleEdit(id)}
              >
                Editar Elementos
              </Button>
              <Button
                type="primary"
                danger
                style={{ marginLeft: 5 }}
                onClick={() => handleDeleteTag(id)}
              >
                Eliminar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={0}>
              <Space>
                <Tooltip title="Editar">
                  <Button type="primary" shape="circle" onClick={() => handleEdit(id)} icon={<EditOutlined />} />
                </Tooltip>
                <Tooltip title="Eliminar">
                  <Button type="danger" shape="circle" onClick={() => handleDeleteTag(id)} icon={<DeleteOutlined />} />
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <PageLayout
      title="Etiquetas"
    >
      <div className="Users">
        <div>
          <div className="Tags">
            <div>
              <Button onClick={() => setTagModal(true)} style={{ marginBottom: '5px' }} type="dashed" icon={<PlusOutlined />}>
                Agregar
              </Button>
              <Table columns={cols} dataSource={tags || []} rowKey="id" />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Agregar Etiqueta"
        centered
        visible={tagModal}
        okText="Agregar"
        onOk={() => {
          form.submit();
        }}
        onCancel={() => setTagModal(false)}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TagForm
            form={form}
            setData={setData}
            targetData={targetData}
            onFinish={onFinish}
          />
        </div>
      </Modal>

      <Modal
        title="Editar Etiqueta"
        centered
        visible={editModal}
        okText="Editar"
        onOk={() => {
          form.submit();
        }}
        onCancel={() => setEditModal(false)}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TagForm
            form={form}
            setupData={selectedTag}
            setData={setData}
            targetData={targetData}
            onFinish={onEdit}
            edit
          />
        </div>
      </Modal>

    </PageLayout>
  );
};

export default Tags;
