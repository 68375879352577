import { SET_LOADING, SET_ERROR } from './types';

const initialState = {
  isLoading: false,
  hasError: false,
  errorMessage: null,
};

export default function chatReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        hasError: payload.value,
        errorMessage: payload.error,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };

    default:
      return state;
  }
}
