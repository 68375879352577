import { setLoading } from '../store/ui/actions';
import axios, { downloadFile } from './axios';
import { store } from '../store';

export const actionHandler = async (
  url = '',
  method = '',
  token = null,
  data = null,
  dispatch = () => {
  },
  cb = () => {
  },
  redirect = false,
  download = false,
  type = 'application/json',
) => {
  const state = store.getState();
  const currentClient = state.auth.selectedClient;
  let headers = token
    ? { Authorization: `Bearer ${token}`, 'Content-Type': type }
    : { 'Content-Type': type };
  if (currentClient) {
    headers = { ...headers, 'X-ISClient': currentClient };
  }
  dispatch(setLoading(true));
  try {
    if (download) {
      await downloadFile(url, headers, {});
    } else {
      const result = await axios({
        method, url, data, headers,
      });
      dispatch(setLoading(false));
      if (result.data) {
        dispatch(cb(result.data));
      } else {
        dispatch(cb());
      }

      if (redirect) redirect();
    }
  } catch (err) {
    dispatch(setLoading(false));
    console.log(err.response.data.message);
    if (!err.message.includes('reading \'type\'')) window.alert(err.response.data.message);
  }
};
