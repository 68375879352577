import React from 'react';
import { connect } from 'react-redux';
import PageLayout from '../../components/Layout/PageLayout';

const Index = (user) => (
  <PageLayout sider={null} title="Home">
    <div className="Index">
      <h4>
        Bienvenido a InStore
        {' '}
        {user.user.user.name}
      </h4>
    </div>
  </PageLayout>
);

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
});

export default connect(mapStateToProps)(Index);
