import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import {
  Modal, Menu, Layout, Select,
} from 'antd';
import {
  AimOutlined,
  UserOutlined,
  FormOutlined,
  ApartmentOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import TeamOutlined from '@ant-design/icons/lib/icons/TeamOutlined';
import TagsOutlined from '@ant-design/icons/lib/icons/TagsOutlined';
import { actionLogout, logout, setCurrentClient } from '../../store/auth/actions';
import Logo from '../../assets/images/nav-logo.svg';
import { selectCurrentUser, selectSelectedClient } from '../../store/auth/auth.selector';
import { clearAssignments } from '../../store/assignments/actions';
import { clearLocations } from '../../store/locations/actions';
import { clearClients } from '../../store/clients/clients.actions';
import { clearUsers } from '../../store/users/actions';
import { clearSurveys } from '../../store/surveys/actions';

const { Header } = Layout;
const { Option } = Select;

const Navigation = (user, { onLogout }) => {
  const [current, setCurrent] = useState('/');
  const [showModal, setShowModal] = useState(false);
  const currentClient = useSelector(selectSelectedClient);
  const selectorCurrentUser = useSelector(selectCurrentUser);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (e) => {
    const { key } = e;
    history.push(key);
    setCurrent(key);
  };

  const changeClient = (value) => {
    if (value === 'na') return;
    dispatch(setCurrentClient(value));
  };

  const clickLogout = () => {
    history.push('/');
    dispatch(actionLogout());
    dispatch(clearAssignments());
    dispatch(clearClients());
    dispatch(clearLocations());
    dispatch(clearSurveys());
    dispatch(clearUsers());
  };

  const init = () => {
    const isWebUser = selectorCurrentUser.roles.find((x) => x.id === 3);
    if (isWebUser) {
      setShowModal(true);
    }
  };

  useEffect(init, []);

  return (
    <Header>
      <Link to="/">
        <img
          src={Logo}
          style={{
            float: 'left',
            width: 110,
            height: 25,
            margin: '16px 24px 16px 0',
          }}
          alt="app logo"
        />
      </Link>
      <button
        type="button"
        onClick={clickLogout}
        style={{
          color: 'white',
          float: 'right',
          backgroundColor: 'inherit',
          border: 'none',
          outline: 'none',
        }}
      >
        Logout
      </button>
      {user.user.roles.find((x) => x.id === 3)
        && (
        <button
          type="button"
          onClick={() => setShowModal(true)}
          style={{
            color: '#1890ff',
            float: 'right',
            backgroundColor: 'inherit',
            border: 'none',
            outline: 'none',
          }}
        >
          Cambiar Cliente
        </button>
        )}
      <Menu
        theme="dark"
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        {user.user.roles.find((x) => x.id === 1)
          && (
          <>
            <Menu.Item key="/clients" icon={<TeamOutlined />}>
              Clientes
            </Menu.Item>
          </>
          )}
        {
          user.user.roles.find((x) => x.id === 3)
          && (
          <Menu.Item key="/tags" icon={<TagsOutlined />}>
            Etiquetas
          </Menu.Item>
          )
        }
        <Menu.Item key="/users" icon={<UserOutlined />}>
          Usuarios
        </Menu.Item>
        <Menu.Item key="/locations" icon={<AimOutlined />}>
          Puntos de venta
        </Menu.Item>
        <Menu.Item key="/surveys" icon={<FormOutlined />}>
          Encuestas
        </Menu.Item>
        <Menu.Item key="/assignments" icon={<ApartmentOutlined />}>
          Asignaciones
        </Menu.Item>
        <Menu.Item key="/reports" icon={<FileExcelOutlined />}>
          Reportes
        </Menu.Item>
      </Menu>

      <Modal
        title="Seleccionar un cliente para continuar"
        visible={showModal}
        closable={false}
        maskClosable={false}
        onOk={() => setShowModal(false)}
        cancelButtonProps={{ disabled: true }}
        okButtonProps={{ disabled: !currentClient }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            defaultValue="na"
            value={currentClient}
            style={{
              width: 300, float: 'right', marginTop: '18px', marginRight: '20px',
            }}
            onChange={changeClient}
          >
            <Option value="na">Seleccionar</Option>
            {user.user.clients && user.user.clients.map((c) => (
              <Option value={c.id} style={{ overflowX: 'scroll' }}>
                {c.name}
                ---------------------------------------------------------------|
              </Option>
            ))}
          </Select>
        </div>
      </Modal>
    </Header>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
});

Navigation.propTypes = {
  onLogout: PropTypes.func,
};

export default connect(mapStateToProps, { onLogout: logout })(Navigation);
