import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Collapse, List, Skeleton, Button, Form, DatePicker, Modal,
} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  createAssignment,
  getAssignments,
  getAssignmentsQuery,
  deleteAssignment,
  getAvailableLocationsForSurvey,
} from '../../store/assignments/actions';
import { getUsers } from '../../store/users/actions';

import { getSurveys } from '../../store/surveys/actions';
import PageLayout from '../../components/Layout/PageLayout';
import AssignmentForm from './AssignmentForm';

export const Assignments = ({
  token,
  users = [],
  locations = [],
  surveys = [],
  assignments = [],
  onGetAssignments = () => {},
  onGetAssignmentsQuery = () => {},
  onGetUsers = () => {},
  onGetSurveys = () => {},
  onGetLocations = () => {},
  onDeleteAssignment = () => {},
  onCreateAssignment = () => {},
}) => {
  const [survey, setSurvey] = useState({});
  const [query, setQuery] = useState('');
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const handleSelectSurvey = (surveyId) => {
    const selectedSurvey = surveyId
      ? surveys.find((s) => s.id === surveyId)
      : {};
    setSurvey(selectedSurvey);
    onGetLocations(selectedSurvey.id, token);
  };

  const handleCreateAssignment = (data, resetForm) => {
    const assignment = {
      userId: data.user,
      selectedLocations: data.selectedLocations.map((i) => i.id),
      surveyId: data.survey,
    };
    onCreateAssignment(assignment, token);
    resetForm();
  };

  const handleShowDeleteModal = (assignmentId) => {
    setAssignmentToDelete(assignmentId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    if (assignmentToDelete) {
      onDeleteAssignment(assignmentToDelete, token);
      setShowDeleteModal(false);
      setAssignmentToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setAssignmentToDelete(null);
  };

  useEffect(() => {
    onGetUsers(token);
    onGetAssignments(token);
    onGetSurveys(token);
  }, [onGetUsers, onGetAssignments, onGetSurveys, token]);

  const customFilter = (lookup, id, assignmentList, extract) => {
    if (!lookup || !id) return [];
    return assignmentList
      .filter((a) => a[lookup].id === id)
      .map((a) => a[extract].id);
  };

  const activeAssignments = assignments.filter((a) => !a.deleted);
  const activeSurveys = surveys.filter((s) => !s.deleted);

  const pollsterUsers = users.filter((u) => u.roles.find((r) => r.id === 2));
  const activeLocations = locations.filter((l) => !l.deleted);
  const takenSurveyLocations = customFilter(
    'survey',
    survey.id,
    activeAssignments,
    'location',
  );
  const locationsBySurvey = activeLocations.filter(
    (l) => !takenSurveyLocations.includes(l.id),
  );

  const handleSearchBySurvey = (surveyId) => {
    const passing = `surveyId=${surveyId}${query}`;
    setSelectedSurvey(surveyId);
    onGetAssignmentsQuery(token, passing);
  };

  const handleFilterChange = (changedValues, allValues) => {
    let dateQuery = '';
    if (allValues.date) {
      dateQuery += `&sCDate=${allValues.date[0].valueOf()}`;
      dateQuery += `&eCDate=${allValues.date[1].valueOf()}`;
      setQuery(dateQuery);
    } else {
      setQuery('');
    }
    if (selectedSurvey) {
      handleSearchBySurvey(selectedSurvey);
    }
  };

  return (
    <PageLayout
      sider={(
        <AssignmentForm
          users={pollsterUsers}
          surveys={activeSurveys}
          locations={locationsBySurvey}
          handleCreateAssignment={handleCreateAssignment}
          handleSelectSurvey={handleSelectSurvey}
          disableLocations={!survey.id}
        />
      )}
      title="Asignaciones"
    >
      <div>
        <div style={{ paddingBottom: '5px' }}>
          <Form form={form} onValuesChange={handleFilterChange} layout="inline">
            <Form.Item name="date">
              <RangePicker locale={locale} />
            </Form.Item>
          </Form>
        </div>
        <Collapse accordion>
          {surveys.map((s) => {
            const surveyAssignments = assignments.filter(
              (a) => a.survey.id === s.id,
            );
            return (
              <Collapse.Panel
                header={`${s.id}. ${s.name} - ${s.description}`}
                key={s.id}
                onClick={() => handleSearchBySurvey(s.id)}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={surveyAssignments}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          danger
                          onClick={() => handleShowDeleteModal(item.id)}
                        >
                          eliminar
                        </Button>,
                      ]}
                    >
                      <Skeleton title={false} active loading={false}>
                        <List.Item.Meta
                          title={(
                            <div>
                              <b>Usuario:</b>
                              {' '}
                              {item.user.email}
                              {' '}
                              {item.user.name}
                            </div>
                          )}
                          description={(
                            <div>
                              <b>PDV:</b>
                              {' '}
                              {`${item.location.name} - ${item.location.address}`}
                            </div>
                          )}
                        />
                        <div>{item.status}</div>
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>

        {/* Delete Confirmation Modal */}
        <Modal
          title="Confirmar"
          visible={showDeleteModal}
          onOk={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          okText="Eliminar"
          cancelText="Cancelar"
        >
          <p>¿Está seguro que desea eliminar esta asignación?</p>
        </Modal>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.currentUser.token,
  users: state.users.data,
  locations: state.assignments.availableLocations,
  surveys: state.surveys.data,
  assignments: state.assignments.data,
});

const mapDispatchToProps = {
  onGetAssignments: getAssignments,
  onGetAssignmentsQuery: getAssignmentsQuery,
  onGetSurveys: getSurveys,
  onGetLocations: getAvailableLocationsForSurvey,
  onGetUsers: getUsers,
  onCreateAssignment: createAssignment,
  onDeleteAssignment: deleteAssignment,
};

Assignments.propTypes = {
  token: PropTypes.string,
  users: PropTypes.array,
  locations: PropTypes.array,
  surveys: PropTypes.array,
  assignments: PropTypes.array,
  onGetAssignments: PropTypes.func,
  onGetAssignmentsQuery: PropTypes.func,
  onGetSurveys: PropTypes.func,
  onGetLocations: PropTypes.func,
  onGetUsers: PropTypes.func,
  onDeleteAssignment: PropTypes.func,
  onCreateAssignment: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);