import { successState } from '../../utils/app/app.actions';
import { actionTypes } from './tags.types';

const initialState = {
  tags: [],
  itemTags: [],
  tag: {},
  addToTag: {},
  edit: '',
  resultList: {},
  itemsTagList: { users: [], locations: [] },
};

const tagsReducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case successState(actionTypes.GET_ALL_TAGS):
      return {
        ...state,
        tags: data,
      };
    case successState(actionTypes.GET_TAG):
      return {
        ...state,
        tag: data,
      };
    case successState(actionTypes.CREATE_TAG):
      return {
        ...state,
        tag: data,
      };
    case successState(actionTypes.EDIT_TAG):
      return {
        ...state,
        tag: data,
      };
    case successState(actionTypes.DELETE_TAG):
      return {
        ...state,
        tag: data,
      };
    case successState(actionTypes.GET_TAGS_FOR_ITEM):
      return {
        ...state,
        itemTags: data,
      };
    case successState(actionTypes.GET_ITEMS_FOR_TAG_LIST_0):
      console.log(state.itemsTagList);
      return {
        ...state,
        itemsTagList: { ...state.itemsTagList, users: data },
      };
    case successState(actionTypes.GET_ITEMS_FOR_TAG_LIST_1):
      console.log(state.itemsTagList);
      return {
        ...state,
        itemsTagList: { ...state.itemsTagList, locations: data },
      };
    case successState(actionTypes.ADD_TAG_TO_ITEM):
      return {
        ...state,
        addToTag: data,
      };
    case successState(actionTypes.REMOVE_TAG_TO_ITEM):
      return {
        ...state,
        addToTag: data,
      };
    case successState(actionTypes.EDIT_QUESTION):
      return {
        ...state,
        edit: data,
      };
    case successState(actionTypes.ASSIGN_BY_LIST):
      return {
        ...state,
        resultList: data,
      };
    default:
      return state || initialState;
  }
};

export default tagsReducer;
