import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Table, Form, Modal, Transfer,
} from 'antd';
import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import ClientForm from './ClientForm';

import {
  getClients,
  createClient,
  getUsersOnClient,
  editUsersOnClient,
  getLocationsOnClient,
  editLocationsOnClient,
  getSurveysOnClient,
  editSurveysOnClient,
} from '../../store/clients/clients.actions';
import {
  selectClients,
  selectLocationsOnClient,
  selectSurveysOnClient,
  selectUsersOnClient,
} from '../../store/clients/clients.selector';
import { useHasErrors } from '../../utils/app/error/error.hooks';
import { actionTypes } from '../../store/clients/clients.types';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';

const { error } = Modal;

const listStyle = {
  width: 300,
  height: 300,
};

const Clients = () => {
  const dispatch = useDispatch();
  const clients = useSelector(selectClients);
  const usersOnClient = useSelector(selectUsersOnClient);
  const locationsOnClient = useSelector(selectLocationsOnClient);
  const surveysOnClient = useSelector(selectSurveysOnClient);

  const [form] = Form.useForm();
  const [clientsTable, setClientsTable] = useState([]);

  const [onClient, setOnClient] = useState([]);
  const [notOnClient, setNotOnClient] = useState([]);
  const [selectedUserKeys, setSelectedUserKeys] = useState([]);

  const [pdvOnClient, setPdvOnClient] = useState([]);
  const [pdvNotOnClient, setPdvNotOnClient] = useState([]);
  const [selectedPdvKeys, setSelectedPdvKeys] = useState([]);

  const [srvOnClient, setSrvOnClient] = useState([]);
  const [srvNotOnClient, setSrvNotOnClient] = useState([]);
  const [selectedSrvKeys, setSelectedSrvKeys] = useState([]);

  const [editUsers, setEditUsers] = useState(false);
  const [editLocations, setEditLocations] = useState(false);
  const [editSurveys, setEditSurveys] = useState(false);

  const [createIsLoading, createIsFinished] = useIsLoading([actionTypes.CREATE_CLIENT]);
  const [createError, createHasError] = useHasErrors([actionTypes.CREATE_CLIENT]);

  const [usersByClientLoading, usersByClientIsFinished] = useIsLoading([actionTypes.USERS_BY_CLIENT]);
  const [usersByClientError, usersByClientHasError] = useHasErrors([actionTypes.USERS_BY_CLIENT]);
  const [editUsersClientsIsLoading, editUsersClientIsFinished] = useIsLoading([actionTypes.EDIT_USERS_BY_CLIENT]);
  const [editUsersClientsError, editUsersClientsHasError] = useHasErrors([actionTypes.EDIT_USERS_BY_CLIENT]);

  const [locationsByClientLoading, locationsByClientIsFinished] = useIsLoading([actionTypes.LOCATIONS_BY_CLIENT]);
  const [locationsByClientError, locationsByClientHasError] = useHasErrors([actionTypes.LOCATIONS_BY_CLIENT]);
  const [editLocationsClientsIsLoading, editLocationsClientIsFinished] = useIsLoading([actionTypes.EDIT_LOCATIONS_BY_CLIENT]);
  const [editLocationsClientsError, editLocationsClientsHasError] = useHasErrors([actionTypes.EDIT_LOCATIONS_BY_CLIENT]);

  const [surveysByClientLoading, surveysByClientIsFinished] = useIsLoading([actionTypes.SURVEYS_BY_CLIENT]);
  const [surveysByClientError, surveysByClientHasError] = useHasErrors([actionTypes.SURVEYS_BY_CLIENT]);
  const [editSurveysClientsIsLoading, editSurveysClientIsFinished] = useIsLoading([actionTypes.EDIT_SURVEYS_BY_CLIENT]);
  const [editSurveysClientsError, editSurveysClientsHasError] = useHasErrors([actionTypes.EDIT_SURVEYS_BY_CLIENT]);

  const init = () => {
    dispatch(getClients());
  };
  useEffect(init, []);

  useEffect(() => {
    if (clients) setClientsTable([...clients]);
  }, [clients]);

  useEffect(() => {
    if (createIsFinished) {
      if (createHasError) {
        error({
          title: '¡Uh-oh!',
          content: createError.message || 'Ocurrio un error inesperado',
        });
      }
    }
  }, [createIsLoading, createHasError]);

  useEffect(() => {
    if (usersByClientIsFinished || editUsersClientIsFinished) {
      if (usersByClientHasError || editUsersClientsHasError) {
        error({
          title: '¡Uh-oh!',
          content: usersByClientError.message || 'Ocurrio un error inesperado',
        });
      } else {
        setOnClient(usersOnClient.withClient.sort((a, b) => (a.name < b.name ? -1 : 1)).map((u) => u.id));
        setNotOnClient([...usersOnClient.withClient, ...usersOnClient.notWithClient]);
        setEditUsers(true);
      }
    }
  }, [usersByClientLoading, usersByClientHasError, editUsersClientsIsLoading, editUsersClientsHasError]);

  useEffect(() => {
    if (locationsByClientIsFinished || editLocationsClientIsFinished) {
      if (locationsByClientHasError || editLocationsClientsHasError) {
        error({
          title: '¡Uh-oh!',
          content: usersByClientError.message || 'Ocurrio un error inesperado',
        });
      } else {
        setPdvOnClient(locationsOnClient.withClient.sort((a, b) => (a.name < b.name ? -1 : 1)).map((u) => u.id));
        setPdvNotOnClient([...locationsOnClient.withClient, ...locationsOnClient.notWithClient]);
        setEditLocations(true);
      }
    }
  }, [locationsByClientLoading, locationsByClientHasError, editLocationsClientsIsLoading, editLocationsClientsHasError]);

  useEffect(() => {
    if (surveysByClientIsFinished || editSurveysClientIsFinished) {
      if (surveysByClientHasError || editSurveysClientsHasError) {
        error({
          title: '¡Uh-oh!',
          content: usersByClientError.message || 'Ocurrio un error inesperado',
        });
      } else {
        setSrvOnClient(surveysOnClient.withClient.sort((a, b) => (a.name < b.name ? -1 : 1)).map((u) => u.id));
        setSrvNotOnClient([...surveysOnClient.withClient, ...surveysOnClient.notWithClient]);
        setEditSurveys(true);
      }
    }
  }, [surveysByClientLoading, surveysByClientHasError, editSurveysClientsIsLoading, editSurveysClientsHasError]);

  const removeClient = (id) => {
    console.log('remove ', id);
  };

  const handleEditUsers = (id) => {
    console.log(`edit clients${id}`);
    dispatch(getUsersOnClient({ id }));
  };

  const handleEditLocations = (id) => {
    console.log(`edit locations${id}`);
    dispatch(getLocationsOnClient({ id }));
  };

  const handleEditSurveys = (id) => {
    console.log(`edit surveys${id}`);
    dispatch(getSurveysOnClient({ id }));
  };

  const onChangeUsers = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    dispatch(editUsersOnClient({ clientId: usersOnClient.clientId, userList: moveKeys, operation }));
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedUserKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChangeLocations = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    dispatch(editLocationsOnClient({ clientId: locationsOnClient.clientId, locationsList: moveKeys, operation }));
  };

  const onSelectChangeLocations = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('locations sourceSelectedKeys:', sourceSelectedKeys);
    console.log('locations targetSelectedKeys:', targetSelectedKeys);
    setSelectedPdvKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChangeSurveys = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    dispatch(editSurveysOnClient({ clientId: surveysOnClient.clientId, surveysList: moveKeys, operation }));
  };

  const onSelectChangeSurveys = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('locations sourceSelectedKeys:', sourceSelectedKeys);
    console.log('locations targetSelectedKeys:', targetSelectedKeys);
    setSelectedSrvKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const cols = [
    {
      title: '',
      dataIndex: 'logo',
      key: 'logo',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditUsers(id)}
          >
            Editar Usuarios
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditLocations(id)}
          >
            Editar Puntos de Venta
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditSurveys(id)}
          >
            Editar Encuestas
          </Button>
          <Button
            type="primary"
            danger
            style={{ marginLeft: 5 }}
            onClick={() => removeClient(id)}
          >
            Eliminar
          </Button>
        </>
      ),
    },
  ];

  const onFinish = async ({ name }) => {
    dispatch(createClient({ name }));
  };

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  return (
    <PageLayout
      sider={
        <ClientForm form={form} onFinish={onFinish} />
          }
      title="Clientes"
    >
      <div className="Users">
        <div>
          <div className="Users">
            <div>
              <Table columns={cols} dataSource={clientsTable} rowKey="id" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Editar Usuarios"
        centered
        visible={editUsers}
        onOk={() => setEditUsers(false)}
        onCancel={() => setEditUsers(false)}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            showSearch
            listStyle={listStyle}
            operations={['Agregar', 'Eliminar']}
            filterOption={filterOption}
            dataSource={notOnClient.map((u) => ({ id: u.id, title: `${u.name}`, key: u.id })).sort((a, b) => (a.title < b.title ? -1 : 1))}
            titles={['Disponibles', 'Actuales']}
            targetKeys={onClient}
            selectedKeys={selectedUserKeys}
            onChange={onChangeUsers}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
          />
        </div>
      </Modal>

      <Modal
        title="Editar Puntos de Venta"
        centered
        visible={editLocations}
        onOk={() => setEditLocations(false)}
        onCancel={() => setEditLocations(false)}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            showSearch
            listStyle={listStyle}
            operations={['Agregar', 'Eliminar']}
            filterOption={filterOption}
            dataSource={pdvNotOnClient.map((u) => ({ id: u.id, title: `${u.name}`, key: u.id })).sort((a, b) => (a.title < b.title ? -1 : 1))}
            titles={['Disponibles', 'Actuales']}
            targetKeys={pdvOnClient}
            selectedKeys={selectedPdvKeys}
            onChange={onChangeLocations}
            onSelectChange={onSelectChangeLocations}
            render={(item) => item.title}
          />
        </div>
      </Modal>

      <Modal
        title="Editar Encuestas"
        centered
        visible={editSurveys}
        onOk={() => setEditSurveys(false)}
        onCancel={() => setEditSurveys(false)}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            showSearch
            listStyle={listStyle}
            operations={['Agregar', 'Eliminar']}
            filterOption={filterOption}
            dataSource={srvNotOnClient.map((u) => ({ id: u.id, title: `${u.name}`, key: u.id })).sort((a, b) => (a.title < b.title ? -1 : 1))}
            titles={['Disponibles', 'Actuales']}
            targetKeys={srvOnClient}
            selectedKeys={selectedSrvKeys}
            onChange={onChangeSurveys}
            onSelectChange={onSelectChangeSurveys}
            render={(item) => item.title}
          />
        </div>
      </Modal>
    </PageLayout>
  );
};

export default Clients;
