import React, { useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@material-ui/icons';

const Conditional = () => {
  const [question, setQuestion] = useState({});

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  return (
    <div>
      <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel}>
        <Form.Item
          name="Question"
          style={{ minWidth: '250px' }}
          label="Pregunta"
        >
          <Input placeholder="Pregunta" allowClear />
        </Form.Item>
        <Form.List
          name="names"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 2) {
                  return Promise.reject(new Error('Ingrese por lo menos 2 opciones'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...formItemLayout}
                  label={index + 1}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Ingrese una opcion.',
                      },
                    ]}
                    noStyle
                  >
                    <Input placeholder="Opcion" style={{ width: '60%' }} />
                  </Form.Item>
                  <>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      style={{ paddingRight: '5px', paddingLeft: '5px' }}
                    />
                    <RightCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Agregar Opcion
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Conditional;
