import React, { useEffect, useState } from 'react';
import { Transfer } from 'antd';

const listStyle = {
  width: 300,
  height: 300,
};

const TagTransfer = (props) => {
  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const {
    data, targetData, setData, type,
  } = props;
  const [selectedKeyData, setSelectedKeyData] = useState([]);

  const [mapData, setMapData] = useState([]);

  const onChangeLocations = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    setData(nextTargetKeys);
  };

  const onSelectChangeLocations = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeyData([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  // Functions
  const init = () => {
    if (type === 0) {
      setMapData(data);
      console.log(data);
    }
    if (type === 1) {
      setMapData(data.withClient);
      console.log(data.withClient);
    }
  };
    // useState
  useEffect(init, []);

  return (
    <div>
      <Transfer
        showSearch
        operations={['Agregar', 'Eliminar']}
        filterOption={filterOption}
        dataSource={mapData && mapData.length > 0 ? mapData.map((u) => ({
          id: u.id,
          title: `${u.name}`,
          key: u.id,
        })).sort((a, b) => (a.title < b.title ? -1 : 1)) : []}
        titles={['Disponibles', 'Actuales']}
        targetKeys={targetData}
        selectedKeys={selectedKeyData}
        onChange={onChangeLocations}
        onSelectChange={onSelectChangeLocations}
        render={(item) => item.title}
      />
    </div>
  );
};

export default TagTransfer;
