import axios from 'axios';
import { apiUrl } from './axios';
import { store } from '../store';

const instance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
});

instance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const accessToken = state.auth.currentUser.token;
    const currentClient = state.auth.selectedClient;
    if (accessToken) {
      config.headers.Authorization = `bearer ${accessToken}`;
    }
    if (currentClient) {
      config.headers['X-ISClient'] = currentClient;
    } else {
      console.log(state.auth.selectedClient);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // alert('La sesión ha expirado')
    }
    return Promise.reject(error);
  },
);

export const $http = instance;
